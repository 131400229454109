<template>
  <div
    @keydown.up.prevent="handleUp"
    @keydown.down.prevent="handleDown"
    data-testid="search-candidates"
  >
    <div class="search-suggest-wrap" v-if="hasCriteria">
      <p class="search-suggest__title">保存した検索条件</p>
      <ul class="search-suggest__list">
        <search-criteria
          :criterion="criterion"
          :index="index"
          :hasFocus="focus_criteria_index === index"
          v-for="(criterion, index) in criteria"
          :key="criterion.id"
        ></search-criteria>
      </ul>
    </div>
    <div class="search-suggest-wrap" v-if="hasHistories">
      <button
        class="search-suggest__button--delete"
        type="button"
        @click="deleteAllHistories"
      >
        すべて消去
      </button>
      <p class="search-suggest__title">最近の検索</p>
      <ul class="search-suggest__list">
        <li
          class="history-list search-suggest__item"
          :class="{ focus: focus_history_index === index }"
          v-for="(history, index) in histories"
          :key="history.id"
        >
          <button
            class="search-history__button text-ellipsis"
            type="button"
            :class="`js-history-focus${index}`"
            @click="goToSearchResults(history, index)"
          >
            <i
              class="glyphs glyphs-search search-suggest__icon"
              aria-hidden="true"
            ></i>
            {{ history }}
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import * as types from '../../store/mutation-types';
import { captureEvent } from '../../../common/utils';
import utils, { UNFOCUSED } from '../../utils';
import SearchCriteria from '../common/SearchCriteria';

export default {
  components: { SearchCriteria },

  watch: {
    focus_candidate_index: function(newIndex, _oldIndex) {
      // フォーカスが外れた場合はSearchInput.vueで処理
      if (newIndex !== UNFOCUSED) {
        let searchButtonEl = null;
        if (newIndex < this.criteria.length) {
          // 検索条件
          searchButtonEl = this.$el.querySelector(
            `.js-criteria-focus${this.focus_criteria_index}`
          );
        } else {
          // 最近の検索
          searchButtonEl = this.$el.querySelector(
            `.js-history-focus${this.focus_history_index}`
          );
        }
        // フォーカスが外れた場合はSearchInput.vueで処理

        searchButtonEl && searchButtonEl.focus();
      }
    }
  },

  computed: {
    ...mapGetters([
      'criteria',
      'histories',
      'focus_candidate_index',
      'focus_criteria_index',
      'focus_history_index'
    ]),

    hasCriteria() {
      return this.criteria.length > 0;
    },

    hasHistories() {
      return this.histories.length > 0;
    }
  },

  methods: {
    ...mapActions([
      'decrementFocusCandidateIndex',
      'incrementFocusCandidateIndex',
      'initializeHistories',
      'disableSearchBox'
    ]),

    deleteAllHistories() {
      if (!confirm('検索履歴を全て消去します。\nよろしいですか？')) {
        return;
      }
      this.initializeHistories();
      this.disableSearchBox();
    },

    goToSearchResults(keyword, index) {
      captureEvent({
        category: 'サイト内検索',
        action: 'SearchPartsClick',
        label: 'recently-search',
        value: index + 1
      });
      this.$store.commit(types.SET_CURRENT_KEYWORD, keyword);
      utils.goToSearchURL('/search/topics', { q: keyword });
    },

    handleUp() {
      this.decrementFocusCandidateIndex();
    },

    handleDown() {
      this.incrementFocusCandidateIndex();
    }
  }
};
</script>
